import React, { useEffect, useRef, useState } from 'react';
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    Box,
    HStack,
    Button,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    useToast,
    VStack, Stack, ButtonGroup
} from '@chakra-ui/react'
import { Head, Link, useForm } from '@inertiajs/react';
import InputError from '@/Components/InputError';
import axios from 'axios';


const Deposit = ({isOpen, onOpen, onClose, nft, balance, listing}) => {

    const { isOpen:isPaymentOpen, onOpen:onPaymentOpen, onClose:onPaymentClose } = useDisclosure()

    const toast = useToast()

    const { data, setData, post, processing, errors, reset, clearErrors} = useForm({
        id: nft.id,
        amount: ''
    });

    useEffect(() => {
        Object.keys(nft).length > 0 ? setData('id', nft.id) : false
    }, [nft])

    const onHandleChange = (event) => {
        setData(event.target.name,  event.target.value)
        errors['amount'] = event.target.value > balance ? 'You dont have enough balance' : ''
    };


    const submit = (e) => {
        e.preventDefault();
        post(route('bid'), {
            onSuccess: (resp) => {
                const error = resp.props.errorBags.default
                //onToast('success', 'Please kindly make payment on the next screen')
                if(error.hasOwnProperty('error')){
                    const error_ = error?.error ? error?.error : 'You encountered an error. Please try again'
                    onToast('error', error_)
                    return
                }

                if(error.hasOwnProperty('amount')){
                    const error_ = error?.amount ? error?.amount : 'You encountered an error. Please try again'
                    onToast('error', error_)
                    return
                }

                if(error.hasOwnProperty('id')){
                    const error_ = error?.id ? error?.id : 'You encountered an error. Please try again'
                    onToast('error', error_)
                    return
                }

                onToast('success', 'You have made an offer successfully')
                onClose()

            },
            onError: (error) => {
                const error_ = error?.error ? error?.error : 'You encountered an error. Please try again'
                onToast('error', error_)
            }
        });
    };

    const onToast = (status, msg) => { 
        toast({
            title: '',
            description: msg,
            status: status,
            duration: 9000,
            isClosable: true,
            position: 'top-right'
        }) 
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => {
                onClose()
                reset()
                clearErrors()
            }} closeOnOverlayClick={false} isCentered size={'2xl'} > 

                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />

                <ModalContent borderRadius={20} backgroundColor={'#111027'}>

                    <ModalHeader zIndex={1}>
                        <Text fontSize={20} mb={1} color={'#fff'}>Bid</Text>
                        <Text fontSize={14} fontFamily={'Cosmata Regular'} className='text-fainted'>Make an offer to own this artwork</Text>
                    </ModalHeader>
                
                    <ModalCloseButton zIndex={1} top={-3} right={-3} backgroundColor={'#fff'} color={'#000'} _hover={{color: '#000'}} borderRadius={100} boxShadow={'rgb(0 0 0 / 16%) 0px 1px 4px'} />

                        <ModalBody zIndex={1}> 

                            <HStack mb={10}>
                                <Box>
                                    <img width={120} src={`${window.location.origin}/${nft.art}`} alt="" />
                                </Box>
                                <Box>
                                    <Text color={'#fff'} fontSize={20} mb={0}>{nft.name}</Text>
                                    <Text color={'#fff'}>{nft.collection}</Text>
                                </Box>
                            </HStack>

                            <Box mb={10} padding={3} border={'1px #bababa solid'} borderRadius={15}>
                                <HStack justifyContent={'space-between'}>
                                    <Text color={'#fff'}>Balance:</Text>
                                    <Text color={'#fff'}>{balance} ETH</Text>
                                </HStack>
                                <HStack justifyContent={'space-between'}>
                                    <Text color={'#fff'}>Floor Price:</Text>
                                    <Text color={'#fff'}>{listing?.amount} ETH</Text>
                                </HStack>
                                <HStack justifyContent={'space-between'}>
                                    <Text color={'#fff'}>Best Offer:</Text>
                                    <Text color={'#fff'}>{listing?.best_offer} ETH</Text>
                                </HStack>
                            </Box>

                            <Box>
                                <Text color={'#fff'} fontWeight={'bold'} marginBottom={2}>Enter your offer</Text>
                                <InputGroup size='md' mb={2}>
                                    <Input 
                                        type={'number'} 
                                        name={'amount'} 
                                        value={data.amount}
                                        placeholder={'1 ETH'} 
                                        onChange={onHandleChange}  
                                        _hover={{borderColor: '#ccf300'}} 
                                        _focus={{borderColor: '#ccf300'}} 
                                        pr='4.5rem' 
                                        size='lg'
                                        className='text-fainted'
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Box marginRight={2} marginLeft={'auto'} marginTop={2} backgroundColor={'#637eeb'} borderRadius={100} padding={1}>
                                            <img width={20} style={{filter: 'brightness(4)'}} src={`${window.location.origin}/images/icons/eth-logo.png`} alt="" />
                                        </Box>
                                    </InputRightElement>
                                </InputGroup>
                                <InputError message={errors.amount} className="mt-2" />
                            </Box>
                                
                        </ModalBody>

                    <ModalFooter>
                        <Button isLoading={processing} onClick={submit}  _hover={{background: 'none'}} _focus={{outline: 0}} width={'100%'} fontSize={20} borderRadius={8} fontFamily={'Boucle2'} className="brand-bg text-dark">Bid</Button>
                    </ModalFooter>
                </ModalContent>


            </Modal>
        </>

    )

}

export default Deposit